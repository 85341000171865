<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>CASE Document</h3>

	<p>The <b>document</b> object in a framework’s CASE JSON serves as the “head” of the framework structure and specifies metadata for the document, including:</p>
	<ul>
		<li><b>Identifier</b> (required): a globally unique identifier meant to serve as an unambiguous reference to the framework/document for any digital system that consumes the framework’s CASE JSON. <span v-html="app_name"/> generates these automatically for new frameworks/documents and they are not editable.</li>
		<li><b>URI Domain</b> (required): Used to form a network-resolvable URI (usually a URL, meaning a resolvable web location) representing the framework/document <ul>
			<li>Many CASE publishing systems use the <span v-html="link('case_rest_api', 'CASE REST API')"></span> URL for the document as the URI values for documents, items, and associations, so that systems downloading CASE JSON produced by <span v-html="app_name"/> can use the URI to later look up and find out if the entity referenced by the URI has changed in any way.</li>
		</ul></li>
		<li><b>Title</b> (required): the title of the framework (e.g. “State Standards for English Language Arts”)</li>
		<li><b>Adoption Status</b>: the publication status for the document. See this <span v-html="link('adoption_status', 'help doc')"></span> for typical adoption status values.</li>
		<li><b>Language</b>: the language in which the framework’s item statements and other text is written</li>
		<li><b>Official Source URL</b>: a link to a website or document (often a PDF) where the framework and/or the competencies in the framework are described. For frameworks that were not originally authored in a CASE publishing system, this is often a link to the PDF that was used as the source for the CASE “translation” of the framework.</li>
		<li><b>Subject(s)</b>: One or more academic areas covered by the framework, such as “Science” or “Fine Arts”</li>
		<li><b>Description</b>: a human-readable description of the framework/document</li>
		<li><b>Creator</b> (required): the creator of the competency framework. For example, the creator for Georgia’s state ELA standards is the Georgia Department of Education.</li>
		<li><b>Publisher</b>: the entity responsible for publishing this competency framework in CASE format. Note that the creator and publisher could be the same entity; or one entity may be publishing a CASE encoding of a framework originally created by another entity.</li>
		<!-- <li><b>Version</b>: ... [most frameworks don't use this and it's confusing to describe]</li> -->
		<li><b>Notes</b>: a space for additional comments about the framework/document</li>
		<li><b>License</b>: the license title and text (a legal description or link explaining what consumers of the framework are allowed to do with the data in the framework). Many agencies leave this area blank or cite a <a href="https://creativecommons.org/share-your-work/cclicenses/" target="_blank">Creative Commons license</a> (e.g. <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">CC BY-NC-SA</a>.)</li>
		<li><b>Last Changed Date</b> (required): The date and time when the framework/document was last changed <ul>
			<li>Note: many systems, including <span v-html="app_name"/>, update the document’s last changed date whenever any item or association in the framework is changed.</li>
		</ul></li>
		<li><strong>Open for Public Review:</strong> Standards Satchel <a href="https://casenetwork.1edtech.org/" target="_blank">dedicated host plans</a> can use this option to display a banner throughout the framework for a public review description (e.g. This framework is open for public review until xx/xx/year). This opens up the commenting feature for non-signed-in users to submit comments visible to roles with Admin and Editor access to frameworks.</li>
	</ul>
	<p>Note that this is not an exhaustive list of all fields that may be included in a document record. For full details see the official <a href="https://www.imsglobal.org/sites/default/files/CASE/casev1p0/information_model/caseservicev1p0_infomodelv1p0.html" target="_blank">CASE 1.0 Information Model</a>.</p>
	<p>Here’s what the CASE JSON looks like for a typical document:</p>
<pre style="font-size:10px; line-height:13px;">
{
  "identifier": "355bdb74-46f9-11e7-9dd8-56d474a21250",
  "uri": "https://state.standards.org/uri/355bdb74-46f9-11e7-9dd8-56d474a21250",
  "title": "State Standards for English Language Arts",
  "creator": "State Department of Education",
  "adoptionStatus": "Adopted",
  "language": "en",
  "lastChangeDateTime": "2022-10-19T16:04:21+00:00",
}
</pre>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	